<template>
  <div>
    <v-row>
      <v-col cols='4'>
        <div>
          <strong>Relatório de impressão</strong>
        </div>
        <div>
          {{selectedValues.filter((v) => !v.blank).length}} documentos
        </div>
        <div v-if='saveMirrorValue'>
          Sequência inicial: {{firstSequence.toString().padStart(10, '0')}}-{{firstSequenceMod}}
        </div>
        <div v-if='saveMirrorValue'>
          Sequência final: {{getLastSequence()}}
        </div>
      </v-col>
      <v-col cols='8' style='align-items: end;'>
        <v-row style='width: 100%; justify-content: end;'>
          <v-col cols='12' md='4' style='display: flex; justify-content: center;'>
            <v-btn
              class='mt-2'
              plain
              min-width='100%'
              elevation='3'
              size='x-large'
              :color='"primary"'
              @click='showConfigs = !showConfigs'
            >
              <v-icon style='margin-right: 10px'>mdi-cog</v-icon>
              {{ $t('CONFIGURATIONS') }}
            </v-btn>
          </v-col>
          <v-col cols='12' md='3' class='ml-4'>
            <v-select
              v-model='printer'
              :items='printers'
              width='100%'
              item-text='name'
              item-value='id'
              label='Impressora'
              :error='printers === ""'
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col class='d-flex justify-end'>
        <v-btn
          color='primary'
          class='mr-4'
          @click='backStep()'
        >
          {{$t('PREVIOUS_STEP')}}
        </v-btn>
        <v-btn
          color='primary'
          class='mr-4'
          :loading='isMountPDF'
          :disabled='selectedValues.length === 0'
          @click='showConfirmModal'
        >
          Imprimir
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if='showConfigs' class='mr-1 ml-1 mb-2'>
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>Configuração de Folha</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.width'
                  type='number'
                  label='Largura'
                  suffix='mm'
                  min='0'
                  required
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.height'
                  type='number'
                  label='Altura'
                  suffix='mm'
                  min='0'
                  required
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.marginPaperLeft'
                  type='number'
                  label='Margem da Esquerda'
                  suffix='mm'
                  min='0'
                  required
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.marginPaperRight'
                  type='number'
                  label='Margem da Direita'
                  suffix='mm'
                  min='0'
                  required
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.marginPaperTop'
                  type='number'
                  label='Margem De Cima'
                  suffix='mm'
                  min='0'
                  required
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.marginPaperBottom'
                  type='number'
                  label='Margem de baixo'
                  suffix='mm'
                  min='0'
                  required
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row v-if='showConfigs' class='mr-1 ml-1'>
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>Configuração de Documento</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.docPerPage'
                  type='number'
                  label='Documentos por página'
                  required
                  min='1'
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.maxPerLine'
                  type='number'
                  label='Máximo por linha'
                  required
                  min='1'
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.marginDocLeft'
                  type='number'
                  label='Margem Documento da Direita'
                  suffix='mm'
                  min='0'
                  required
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='configLayout.marginDocBottom'
                  type='number'
                  label='Margem Documento embaixo'
                  suffix='mm'
                  min='0'
                  required
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row class='mt-5 mr-1 ml-1'>
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>Configuração do Grid</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols='12' md='2'>
                <v-checkbox
                  v-model='grid.show'
                  label='Mostrar Grid em tela'
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='grid.width'
                  type='number'
                  label='Tamanho da coluna'
                  suffix='mm'
                  required
                />
              </v-col>
              <v-col cols='12' md='2'>
                <v-text-field
                  v-model='grid.height'
                  type='number'
                  label='Tamanho da linha'
                  suffix='mm'
                  required
                />
              </v-col>
              <v-col cols='12' md='4'>
                <v-slider
                  v-model='grid.opacity'
                  label='Opacidade das linhas'
                  max='1'
                  min='0'
                  thumb-label='always'
                  step='0.1'
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row style='flex: 1; overflow-y: auto'>
      <v-carousel
        v-if='selectedTemplate !== "" && page_documents.length > 0'
        id='carousel'
        hide-delimiters
        height='configLayout.height'
        :style='{"background-color": "#f2edf9", "width": "100%", "padding": "100px"}'
        :show-arrows='page_documents.length > 1'
      >
        <v-carousel-item
          v-for='(page, index) in page_documents'
          :key='index'
          eager
          cover
          :style='{"align-self": "center"}'
        >
          <v-card
            :id='`documentPage-${index}`'
            class='documentPage'
            :style='{
              width: `${configLayout.width}mm`,
              height: `${configLayout.height}mm`,
              "padding-top": `${configLayout.marginPaperTop || 0}mm`,
              "padding-left": `${configLayout.marginPaperLeft || 0}mm`,
              "padding-right": `${configLayout.marginPaperRight || 0}mm`,
              "padding-bottom": `${configLayout.marginPaperBottom || 0}mm`,
              "border-radius": "10px",
              "box-shadow": "8px 8px 50px 8px rgba(0,0,0,0.3)",
            }'
          >
            <template
              v-for='(document, indexDocument) in page'
            >
              <div
                :id='document.name'
                :key='document.name'
                :style='{
                  "margin-bottom": verifyLine(indexDocument) ? "0mm" : `${configLayout.marginDocBottom}mm`,
                  "margin-left": (indexDocument) % configLayout.maxPerLine === 0 ? "0mm" : `${configLayout.marginDocLeft}mm`,
                  "display": "inline-block",
                  "position": "relative",
                }'
              >
                <Canvas
                  :id='document.name'
                  :values='document'
                  :template='template'
                  :template-layout='template'
                  @isFrontAndBack='setIsFrontAndBack'
                  @pageLayout='setPageLayout'
                  @saveMirror='setSaveMirror'
                  @sizeLayout='setSizeLayout'
                  @fieldToSave='setFieldToSave'
                  @validationError='showError'
                />
              </div>
              <div
                v-if='newLine(indexDocument)'
                :key='`${document.name}_${indexDocument}`'
                :style='{
                  "flex-basis": "100%",
                  "height": 0,
                }'
              />
            </template>
            <div v-if='grid.show'>
              <div
                v-for='countRows in parseInt(configLayout.height / grid.height) - 1'
                :key='countRows'
                class='grid-rows'
                :style='{
                  "width": `${configLayout.width}mm`,
                  "border": "1px solid black",
                  "opacity": `${parseFloat(grid.opacity)}`,
                  "top": `${grid.height * countRows}mm`,
                  "left": "0px",
                  "position": "absolute"
                }'
              />
            </div>
            <div v-if='grid.show'>
              <div
                v-for='countColumns in parseInt(configLayout.width / grid.width) - 1'
                :key='countColumns'
                class='grid-columns'
                :style='{
                  "height": `${configLayout.height}mm`,
                  "border": "1px solid black",
                  "opacity": `${parseFloat(grid.opacity)}`,
                  "top": "0px",
                  "left": `${grid.width * countColumns}mm`,
                  "position": "absolute"
                }'
              />
            </div>
          </v-card>
        </v-carousel-item>
      </v-carousel>
    </v-row>
    <v-row justify='center'>
      <v-dialog
        v-model='dialog'
        fullscreen
        hide-overlay
        transition='dialog-bottom-transition'
      >
        <v-toolbar
          dark
          color='primary'
        >
          <v-btn
            icon
            dark
            @click='dialog = false'
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Configuração da impressora</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              dark
              color='secondary'
              text
              @click='dialog = false'
            >
              {{$t('SAVE')}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card>
          <v-card-text>
            <Row>
              <v-col>
                <v-select
                  v-model='printer'
                  label='Tipo de papel (bandeja)'
                />
              </v-col>
            </Row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import axios from 'axios';
  import { calculaDigitoMod11 } from '../../../util/mod';
  import { base64ToArrayBuffer } from '../../../util/file';

  export default {
    name: 'TemplatePage',
    props: {
      selected: {
        type: Array,
        required: true,
      },
      template: {
        type: Object,
        required: true,
      },
    },
    mounted: function () {
      this.getValues();
      this.getFirstSequence();
      this.getAllPrinters();
      this.setPrintConfigByTemplate();
      this.setPaperLayout();
      this.prepareCarouselPages();
    },

    data: function () {
      return {
        selectedValues: [],
        dialog: false,
        printers: [],
        printer: '',
        isMountPDF: false,
        firstSequence: '',
        firstSequenceMod: '',
        isFrontAndBack: false,
        saveMirrorValue: false,
        fieldToSave: '',
        selectedTemplate: '',
        widthLayout: '',
        heightLayout: '',
        grid: {
          height: 5,
          width: 5,
          show: false,
          opacity: 0.5,
        },
        configLayout: {
          docPerPage: 8,
          marginPaperLeft: 17.92,
          marginPaperRight: 17.92,
          marginPaperTop: 11.67,
          marginPaperBottom: 11.67,
          marginDocLeft: 15,
          marginDocBottom: 11.67,
          maxPerLine: 4,
          externalLeftMargin: 0,
          externalRightMargin: 0,
          externalTopMargin: 0,
          externalBottomMargin: 0,
          width: 297,
          height: 218,
        },
        showConfigs: false,
        firstIndex: 0,
        lastIndex: 0,
        page_documents: [],
        showArrows: true,
        model: [],
        zoomLevel: 1,
        utilizedHeight: 0,
        lines: 0,
      };
    },

    components: {
      Canvas: () => import('../components/Canvas/CNH/Canvas'),
    },
    watch: {
      'configLayout.docPerPage': {
        handler (newVal) {
          if (newVal) {
            this.prepareCarouselPages();
          }
        },
        immediate: true,
      },
    },
    methods: {
      setPrintConfigByTemplate: function () {
        this.configLayout.docPerPage = Number(this.template.documents_per_page) || 8;
        this.configLayout.maxPerLine = Number(this.template.documents_per_line) || 4;
        this.configLayout.marginDocLeft = Number(this.template.distance_to_left_document) || 15;
        this.configLayout.marginDocBottom = Number(this.template.distance_to_bottom_document) || 15;
        this.configLayout.width = Number(this.template.sheet_internal_width) || 297;
        this.configLayout.height = Number(this.template.sheet_internal_height) || 218;
      },
      getValues: function () {
        this.selectedValues = this.selected;
        this.selectedTemplate = this.template;
        this.$emit('selected', this.selectedValues);
      },
      setIsFrontAndBack: function () {
        this.isFrontAndBack = this.template.front_back_print;
      },
      setSaveMirror: function (value) {
        this.saveMirrorValue = value;
      },
      setFieldToSave: function () {
        const field = this.selectedTemplate.layout_fields.find((item) => Number(item.id) === Number(this.selectedTemplate.key_field_id));
        this.fieldToSave = field.name.toLowerCase();
      },
      setPageLayout: function () {
        this.configLayout.externalLeftMargin = this.selectedTemplate.sheet_external_margin_left;
        this.configLayout.externalRightMargin = this.selectedTemplate.sheet_external_margin_right;
        this.configLayout.externalBottomMargin = this.selectedTemplate.sheet_external_margin_bottom;
        this.configLayout.externalTopMargin = this.selectedTemplate.sheet_external_margin_top;
      },
      setSizeLayout: function () {
        this.widthLayout = Math.floor(this.selectedTemplate.width);
        this.heightLayout = Math.floor(this.selectedTemplate.height);
      },
      setPaperLayout: function () {
        // if (!value) return;
        this.configLayout.width = this.template.sheet_internal_width;
        this.configLayout.height = this.template.sheet_internal_height;
        this.configLayout.marginPaperLeft = this.template.sheet_internal_margin_left;
        this.configLayout.marginPaperRight = this.template.sheet_internal_margin_right;
        this.configLayout.marginPaperTop = this.template.sheet_internal_margin_top;
        this.configLayout.marginPaperBottom = this.template.sheet_internal_margin_bottom;
        this.configLayout.marginDocLeft = this.template.distance_to_left_document;
        this.configLayout.marginDocBottom = this.template.distance_to_bottom_document;
      },
      mountPDF: async function () {
        if (this.printer === '') {
          Swal.fire({
            icon: 'warning',
            title: 'Impressora não selecionada',
            text: 'Você deve selecionar uma impressora antes de enviar o documento',
          });
          return;
        }

        try {
          this.isMountPDF = true;
          const gridColunsList = Array.from(document.getElementsByClassName('grid-columns'));
          const gridRowsList = Array.from(document.getElementsByClassName('grid-rows'));
          const externalGridList = Array.from(document.getElementsByClassName('grid-external-margin'));

          gridColunsList.forEach((columns) => {
            columns.remove();
          });

          gridRowsList.forEach((rows) => {
            rows.remove();
          });

          externalGridList.forEach((columns) => {
            columns.style.border = '';
          });

          let finalHtml = '<html><head><link href="https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css" rel="stylesheet"></head><body>';

          const slides = document.querySelectorAll('.documentPage');

          slides.forEach((slide) => {
            slide.style.marginLeft = '';
            slide.style.marginRight = '';
            slide.style.marginTop = '';
            slide.style.marginBottom = '';
            slide.style.boxShadow = '';
            slide.style.border = '';

            const backgrounds = document.querySelectorAll('img.background');

            backgrounds.forEach((background) => {
              background.remove();
            });

            slide.innerHTML = slide.innerHTML.replaceAll('v-sheet', '');

            finalHtml += slide.outerHTML;
          });

          finalHtml += '</body></html>';
          finalHtml = finalHtml.replaceAll('transform:', '-webkit-transform:');
          console.log(finalHtml); /* eslint-disable-line no-console */

          const configLayout = {
            width: this.configLayout.width,
            height: this.configLayout.height,
          };

          axios({
            url: '/document/print',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'POST',
            responseType: 'json',
            withCredentials: true,
            data: {
              printerId: this.printer,
              htmlBase64: finalHtml,
              templateId: this.selectedTemplate.id,
              configLayout,
            },
          }).then(({ data }) => {
            if (data && data.pdf) {
              const blob = new Blob([base64ToArrayBuffer(data.pdf)], { type: 'application/pdf' });
              const blobUrl = URL.createObjectURL(blob);

              const printWindow = window.open(blobUrl);
              if (printWindow) {
                printWindow.onload = function () {
                  printWindow.print();
                };
              }
            }
            this.isMountPDF = false;
            // html.style.border = '2px dashed black';
            this.$emit('linkRenach', this.saveMirrorValue);
            this.$emit('fieldToSave', this.fieldToSave);
            this.$emit('next');
          }).catch((e) => {
            console.error(e); /* eslint-disable-line no-console */
            this.isMountPDF = false;
            this.printFailRetry();
          });
        } catch (e) {
          this.isMountPDF = false;
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      getAllPrinters: async function () {
        try {
          const result = await axios({
            url: '/printers',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          this.printers = result.data.printers;

          if (this.printers.length === 1) {
            this.printer = this.printers[0].id;
          }
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
      prepareCarouselPages () {
        let firstIndex = 0;
        this.page_documents = [];
        while (firstIndex < this.selectedValues.length) {
          const lastIndex = Number(firstIndex) + Number(this.configLayout.docPerPage);
          this.page_documents.push(this.selectedValues.slice(firstIndex, lastIndex));
          firstIndex = Number(lastIndex);
        }

        this.model = this.page_documents;
      },
      getArrayFormat (indexPage) {
        const index = Number(indexPage) - 1;
        return this.page_documents[index];
      },
      backStep: function () {
        this.$emit('back');
      },
      base64ToImage: function (img) {
        if (img) {
          return `data:image/jpg;base64,${img}`;
        }
        return null;
      },
      verifyLine: function (index) {
        const lastLineDocuments = (this.page_documents[0].length % this.configLayout.maxPerLine);
        const lastIndex = (this.page_documents[0].length - lastLineDocuments);
        return index >= lastIndex;
      },
      newLine: function (index) {
        if (Number(this.configLayout.maxPerLine) === 1) {
          return true;
        }
        return index >= 1 ? (index) % this.configLayout.maxPerLine === (this.configLayout.maxPerLine - 1) : false;
      },
      printFailRetry: function () {
        Swal.fire({
          icon: 'error',
          title: 'Falha na impressão',
          text: 'Houve uma falha de conexão com a impressora. Deseja tentar novamente?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            this.mountPDF();
          }
        });
      },
      showConfirmModal: function () {
        Swal.fire({
          icon: 'warning',
          text: 'Deseja confirmar operação?',
          confirmButtonText: 'Sim',
          showCancelButton: true,
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            this.mountPDF();
            this.$emit('firstSequence', this.firstSequence);
          }
        });
      },
      getFirstSequence: async function () {
        try {
          this.$store.dispatch('loading/toggleLoading');
          const result = await axios({
            url: '/first-sequence',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });
          this.firstSequence = result.data.firstSequence.sequence;
          this.firstSequenceMod = result.data.firstSequence.sequence_mod;
          this.$store.dispatch('loading/toggleLoading');
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
          this.$store.dispatch('loading/toggleLoading');
        }
      },
      getLastSequence: function () {
        const lastSequence = (this.firstSequence + this.selectedValues.length - 1).toString().padStart(10, '0');
        const lastSequenceMod = calculaDigitoMod11(lastSequence);

        return `${lastSequence}-${lastSequenceMod}`;
      },
      showError: function ({ title, text }) {
        Swal.fire({
          icon: 'error',
          title,
          text,
        });
      },
    },
  };
</script>

<style>
  .break {
    flex-basis: 100%;
    height: 0;
  }

  .zoom-buttons {
    display: flex;
    gap: 10px; /* Ajuste o valor conforme necessário */
  }

  .zoom-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Ajuste o tamanho conforme necessário */
    height: 40px; /* Ajuste o tamanho conforme necessário */
    border: none;
    border-radius: 5px;
    background-color: #f0f0f0; /* Cor de fundo */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra */
    cursor: pointer;
  }

  .mirrored-icon span {
    transform: scaleX(-1); /* Espelha o ícone horizontalmente */
  }

  .zoom-button span {
    font-size: 24px; /* Tamanho do ícone */
    color: #333; /* Cor do ícone */
  }

  .zoom-button:hover {
    background-color: #e0e0e0; /* Cor de fundo ao passar o mouse */
  }
</style>
